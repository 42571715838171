/* Container for the onboarding message */
.onboarding-message {
  position: absolute;
  background-color: #f0e6d2; /* Light neutral background */
  color: #2b2b2b; /* Dark text for readability */
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  z-index: 1000;
  font-family: Arial, sans-serif;
}

/* Text content */
.onboarding-message .message-text {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #3a7d44; /* Greenish color for emphasis */
}

/* Control buttons container */
.onboarding-message .message-controls {
  display: flex;
  justify-content: space-between;
}

/* Buttons */
.onboarding-message button {
  background-color: #ff7043; /* Accent orange */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Hover and focus states for buttons */
.onboarding-message button:hover,
.onboarding-message button:focus {
  background-color: #f4c542; /* Highlight yellow on hover */
  outline: none;
}

/* Back button with a neutral tone */
.onboarding-message .prev-button {
  background-color: #6d6d6d; /* Neutral gray */
}

.onboarding-message .prev-button:hover,
.onboarding-message .prev-button:focus {
  background-color: #3a7d44; /* Greenish color for hover */
}

/* Finish button with a more assertive look */
.onboarding-message .finish-button {
  background-color: #3a7d44; /* Greenish assertive tone */
}

.onboarding-message .finish-button:hover,
.onboarding-message .finish-button:focus {
  background-color: #2b2b2b; /* Darker shade for hover */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .onboarding-message {
    max-width: 90%;
    padding: 12px;
  }
  .onboarding-message .message-text {
    font-size: 14px;
  }
  .onboarding-message button {
    font-size: 12px;
    padding: 6px 8px;
  }
}

.onboarding-overlay {
  transition: opacity 0.3s ease;
}

.onboarding-highlight {
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  background: transparent; /* Ensures it's not dimmed */
}

.onboarding-message {
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1003;
}
