.onboarding-message {
  color: #2b2b2b;
  z-index: 1000;
  background-color: #f0e6d2;
  border-radius: 8px;
  max-width: 300px;
  padding: 16px;
  font-family: Arial, sans-serif;
  position: absolute;
  box-shadow: 0 4px 8px #0000001a;
}

.onboarding-message .message-text {
  color: #3a7d44;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
}

.onboarding-message .message-controls {
  justify-content: space-between;
  display: flex;
}

.onboarding-message button {
  color: #fff;
  cursor: pointer;
  background-color: #ff7043;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .3s;
}

.onboarding-message button:hover, .onboarding-message button:focus {
  background-color: #f4c542;
  outline: none;
}

.onboarding-message .prev-button {
  background-color: #6d6d6d;
}

.onboarding-message .prev-button:hover, .onboarding-message .prev-button:focus, .onboarding-message .finish-button {
  background-color: #3a7d44;
}

.onboarding-message .finish-button:hover, .onboarding-message .finish-button:focus {
  background-color: #2b2b2b;
}

@media (width <= 600px) {
  .onboarding-message {
    max-width: 90%;
    padding: 12px;
  }

  .onboarding-message .message-text {
    font-size: 14px;
  }

  .onboarding-message button {
    padding: 6px 8px;
    font-size: 12px;
  }
}

.onboarding-overlay {
  transition: opacity .3s;
}

.onboarding-highlight {
  background: none;
  transition: box-shadow .3s, border-color .3s;
}

.onboarding-message {
  z-index: 1003;
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px #0003;
}
/*# sourceMappingURL=index.9a866c65.css.map */
